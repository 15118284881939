import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { maskString } from "../../utils/maskString";
import { Button } from "./Button";
import { auth } from "../../utils/firebase";
import { CheckIcon } from "@heroicons/react/20/solid";
import { ClipLoader } from "react-spinners";
import axios from "axios";

export const IndividualBotSetting = forwardRef((props, ref) => {
  const [buttonText, setButtonText] = useState("Save");
  const [slippage, setSlippage] = useState(0.2);
  const [speed, setSpeed] = useState("low");
  const [frequency, setFrequency] = useState(3);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      setLoading(true);
      const user = auth.currentUser;
      if (user) {
        const idToken = await user.getIdToken();
        const payload = {
          id_token: idToken,
          wallet_address: props.walletAddress,
        };
        const res = await axios.get("https://bot.gomemex.com/setting", {
        // const res = await axios.get("http://localhost:8000/setting", {
          params: payload,
        });
        if (res.data) {
          setSlippage(Number(res.data.payload.slippage) / 100);
          setSpeed(res.data.payload.speed);
          setFrequency(Number(res.data.payload.frequency));
        }
      }
      setLoading(false);
    };
    fetchSettings();
  }, []);

  const saveSetting = async () => {
    setLoading(true);
    const user = auth.currentUser;
    if (user) {
      const idToken = await user.getIdToken();

      const validatedSlippage =
        slippage === "" || slippage === 0 ? 0.2 : slippage;
      const validatedFrequency =
        frequency === "" || frequency === 0 ? 3 : frequency;

      const payload = {
        id_token: idToken,
        wallet_address: props.walletAddress,
        slippage: 100 * validatedSlippage,
        speed: speed,
        frequency: validatedFrequency,
      };

      const res = await axios.post("https://bot.gomemex.com/setting", payload);
      // const res = await axios.post("http://localhost:8000/setting", payload);
      if (res.data.status) {
        setButtonText(
          <CheckIcon className="h-6 w-6 cursor-pointer" aria-hidden="true" />
        );
        setSettings(validatedSlippage, speed, validatedFrequency);
      }
    }
    setLoading(false);
  };

  const setSettings = (slippage, speed, frequency) => {
    setSlippage(slippage);
    setSpeed(speed);
    setFrequency(frequency);
  };

  useImperativeHandle(ref, () => ({
    setSettings,
  }));

  return (
    <div className="w-full flex gap-2 text-center mt-2 items-center">
      {/* Wallet address */}
      <div className="w-[20%]">{maskString(props.walletAddress, 15)}</div>
      {/* SlippageBps */}
      <div className="w-[25%]">
        {loading ? (
          <ClipLoader
            color="#999999"
            size={24}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <input
            type="number"
            min="0.50"
            max="3.00"
            step="0.01"
            onKeyDown={(e) => {
              if (
                e.key === "e" ||
                e.key === "E" ||
                e.key === "+" ||
                e.key === "-"
              ) {
                e.preventDefault();
              }
            }}
            value={slippage}
            onChange={(e) => {
              const value = e.target.value;

              // Allow empty value
              if (value === "") {
                setSlippage("");
                return;
              }

              // Convert to float and validate range and decimal places
              const floatValue = parseFloat(value);
              const isValid =
                !isNaN(floatValue)
              const hasTwoDecimalsOrLess = /^\d*\.?\d{0,2}$/.test(value);

              if (isValid && hasTwoDecimalsOrLess) {
                setSlippage(value);
              }
            }}
            className="hover:cursor-text bg-transparent border-0 focus:border-b-2 focus:border-b-themeColor-light text-center p-0 text-[18px] w-2/3 focus:outline-none focus:ring-0"
          />
        )}
      </div>
      {/* Speed - Prioritization Fee */}
      <div className="w-[25%]">
        {loading ? (
          <ClipLoader
            color="#999999"
            size={24}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <select
            value={speed}
            onChange={(e) => {
              setSpeed(e.target.value);
            }}
            className="hover:cursor-pointer bg-transparent border-0 text-center p-0 w-2/3 focus:outline-none focus:ring-0 text-[18px]"
          >
            <option value="low">Fast</option>
            <option value="medium">Turbo</option>
            <option value="high">Ultra</option>
            <option value="auto">Auto</option>
          </select>
        )}
      </div>
      {/* Frequency */}
      <div className="w-[20%]">
        {loading ? (
          <ClipLoader
            color="#999999"
            size={24}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <input
            type="number"
            min="1"
            max="10"
            step="1"
            onKeyDown={(e) => {
              if (
                e.key === "e" ||
                e.key === "E" ||
                e.key === "+" ||
                e.key === "-"
              ) {
                e.preventDefault();
              }
            }}
            value={frequency}
            onChange={(e) => {
              const value = e.target.value;
              if (value === "") {
                setFrequency(""); // Allow empty value
              } else {
                const intValue = parseInt(value, 10);
                if (!isNaN(intValue) && intValue >= 1 && intValue <= 10) {
                  setFrequency(intValue);
                }
              }
            }}
            className="bg-transparent border-0 focus:border-b-2 focus:border-b-themeColor-light text-center p-0 text-[18px] w-2/3 focus:outline-none focus:ring-0"
          />
        )}
      </div>
      {/* Save Button */}
      <div className="w-[10%]">
        <Button
          isActive={loading}
          buttonText={buttonText}
          handleClick={saveSetting}
          classNames="w-full bg-themeColor-light hover:bg-themeColor-light/70"
        />
      </div>
    </div>
  );
});
