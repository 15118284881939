import { useState, useEffect } from "react";
import { db, auth } from "../../utils/firebase";
import { getDocs, collection } from "firebase/firestore";
import { ActionButton } from "./ActionButton";
import { CancelButton } from "./CancelButton";

export const ConfirmPanel = ({ handleProgressIndex, amount, balance, token }) => {
  const [error, setError] = useState("");
  const [walletNum, setWalletNum] = useState(0);
  const [active, setActive] = useState(true);
  const [loading, setLoading] = useState(false)

  // Fetch Wallet Addresses
  const fetchWalletAddresses = async () => {
    setLoading(true)
    const currentUser = auth.currentUser;
    const pathToCollection = `bots/${currentUser?.uid}/botDetails`;
    const collectionRef = collection(db, pathToCollection);
    const querySnapshot = await getDocs(collectionRef);
    const bots = [];
    querySnapshot.forEach((doc) => {
      bots.push(doc.id);
    });
    setWalletNum(bots.length);
    setLoading(false)
  };

  const goToSend = () => {
    handleProgressIndex(2);
  };

  const goToPrepare = () => {
    handleProgressIndex(0);
  };

  useEffect(() => {
    fetchWalletAddresses();
  }, []);

  useEffect(() => {
    let isEnoughBalance = false
    if (amount * walletNum > balance - 0.0001) {
      setError("Not Enough Balance");
      isEnoughBalance = false
    } else {
      setError("");
      isEnoughBalance = true
    }
    setActive(!loading && isEnoughBalance)
  }, [walletNum, amount, balance, loading]);

  return (
    <>
      {/* Panel */}
      <div className="grid grid-cols-2 gap-1 mt-6 w-full px-0 lp:px-6 py-4">
        <div className="flex flex-col gap-1 items-start text-left bg-panelColor2-light px-4 py-2">
          <span className="text-xl">{walletNum}</span>
          <span className="text-sm opacity-70">Total number of wallets</span>
        </div>
        <div className="flex flex-col gap-1 items-start text-left bg-panelColor2-light px-4 py-2">
          <span className="text-xl">
            {Math.round(amount * walletNum * Math.pow(10, token.decimals)) / Math.pow(10, token.decimals)} {token.symbol}
          </span>
          <span className="text-sm opacity-70">
            Total number of tokens to be sent
          </span>
        </div>
        <div className="flex flex-col gap-1 items-start text-left bg-panelColor2-light px-4 py-2">
          <span className="text-xl">1</span>
          <span className="text-sm opacity-70">
            Total number of transactions needed
          </span>
        </div>
        <div className="flex flex-col gap-1 items-start text-left bg-panelColor2-light px-4 py-2">
          <span className="text-xl">{balance} {token.symbol}</span>
          <span className="text-sm opacity-70">Your {token.symbol} balance</span>
        </div>
      </div>
      {/* Next Button */}
      <div className="px-0 lp:px-6 w-full flex justify-between gap-3 break-words text-wrap mt-3">
        {/* Available SOL */}
        <div className="flex gap-3 text-sm items-end opacity-70">
          <span className="text-red-600">{error}</span>
        </div>
        {/* Button */}
        <div className="flex gap-2">
          <CancelButton handleClick={goToPrepare} buttonText="Back" />
          <ActionButton
            buttonText="Next"
            handleClick={goToSend}
            disabled={!active}
          />
        </div>
      </div>
    </>
  );
};
