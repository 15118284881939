export const ActionButton = ({handleClick, buttonText, disabled=false}) => {
  return (
    <button
      onClick={handleClick}
      disabled={disabled}
      className={`px-4 py-1 h-[32px] bg-themeColor-light text-btnTextColor-light ${disabled ? "hover:cursor-not-allowed" : "hover:cursor-pointer hover:bg-themeColor-light/70"}`}
    >
      {buttonText}
    </button>
  );
};
