import { useState, useEffect } from "react";
import { ActionButton } from "./ActionButton";
import { getApprovedTokens } from "../../hooks/handleApprovedTokens";
import { TokensListModal } from "./TokensListModal";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { ClipLoader } from "react-spinners";

export const PreparePanel = ({
  handleProgressIndex,
  balance,
  balanceLoading,
  handleAmount,
  token,
  handleToken,
}) => {
  const [active, setActive] = useState(false);
  const [amount, setAmount] = useState("");
  const [tokenList, setTokenList] = useState([]);
  const [showTokenListModal, setShowTokenListModal] = useState(false);

  useEffect(() => {
    const fetchTokenList = async () => {
      const res = await getApprovedTokens();
      const list = [];
      for (const token in res) {
        list.push(res[token]);
      }
      list.sort((a, b) => {
        if (a.symbol < b.symbol) return -1;
        if (a.symbol > b.symbol) return 1;
        return 0;
      });
      setTokenList(list);
    };

    fetchTokenList();
  }, []);

  useEffect(() => {
    let isValidAmount = false

    // eslint-disable-next-line
    if (amount == 0 || amount === "") {
      isValidAmount = false
    } else {
      isValidAmount = true
      handleAmount(amount);
    }
    setActive(isValidAmount && !balanceLoading);
    // eslint-disable-next-line
  }, [amount, balanceLoading]);

  const goToConfirm = () => {
    handleProgressIndex(1);
  };

  return (
    <>
      {/* Panel */}
      <div className="px-2 lp:px-6 w-full flex gap-8 break-words text-wrap mt-6">
        {/* Token Select */}
        <div className="flex flex-col gap-2 items-start w-2/3">
          <label>Token</label>
          <div
            className="flex gap-3 pb-2 justify-between items-center w-full hover:cursor-pointer hover:opacity-70"
            onClick={() => setShowTokenListModal(true)}
          >
            <div className="flex gap-3 items-center">
              <img
                src={token.logo}
                alt={token.name}
                className="w-8 h-8 rounded-md"
              />
              <span className="text-sm lp:text-[16px]">{token.symbol}</span>
            </div>
            <ChevronDownIcon className="w-4 h-4" />
          </div>
        </div>
        {/* Input Amount */}
        <div className="flex flex-col gap-2 items-start w-1/3">
          <label>Amount</label>
          <input
            type="number"
            value={amount}
            onKeyDown={(e) => {
              if (
                e.key === "e" ||
                e.key === "E" ||
                e.key === "+" ||
                e.key === "-"
              ) {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              const value = e.target.value;

              // Allow empty value
              if (value === "") {
                setAmount("");
                return;
              }

              // Convert to float and validate range and decimal places
              const floatValue = parseFloat(value);
              const isValid = !isNaN(floatValue);
              const hasTwoDecimalsOrLess = /^\d*\.?\d{0,9}$/.test(value);

              if (isValid && hasTwoDecimalsOrLess) {
                setAmount(value);
              }
            }}
            className="w-full hover:cursor-text bg-transparent border-0 border-b-2 border-b-themeColor-light/70 focus:border-b-themeColor-light text-center py-1 text-[18px] focus:outline-none focus:ring-0"
          />
        </div>
      </div>
      {/* Next Button */}
      <div className="px-2 lp:px-6 w-full flex justify-between gap-3 break-words text-wrap mt-6">
        {/* Available SOL */}
        <div className="flex gap-3 text-sm items-end opacity-70">
          <span className="flex gap-1">
            <span className="hidden lp:flex">Available</span>
            <span>{token.symbol} :</span>
          </span>
          <span className="flex items-center">
            {balanceLoading ? (
              <ClipLoader
                color="#000000"
                size={18}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              balance
            )}
          </span>
        </div>
        {/* Button */}
        <ActionButton
          buttonText="Next"
          handleClick={goToConfirm}
          disabled={!active}
        />
      </div>
      <TokensListModal
        showStatus={showTokenListModal}
        setShowStatus={setShowTokenListModal}
        tokenList={tokenList}
        setSelectedToken={handleToken}
      />
    </>
  );
};
