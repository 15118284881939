import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { fetchMetrics } from "../../hooks/functions";
import { IndividualMetrics } from "./IndividualMetrics";

export const MetricsModal = ({ tokenList, openModal, setOpenModal }) => {
  const [metrics, setMetrics] = useState({});
  const [summary, setSummary] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchMetrics();
      const temp = {};
      const sum = {};
      for (const row of res) {
        const walletAddress = row["wallet_address"];
        const mint = row["output_mint"];

        if (!temp[walletAddress]) {
          temp[walletAddress] = [];
        }

        if (!sum[mint]) {
          sum[mint] = {
            totalUsdValue24h: 0,
            totalUsdValue: 0,
          };
        }

        temp[walletAddress].push(row);
        sum[mint]["totalUsdValue24h"] += Number(row["total_usd_value_24h"]);
        sum[mint]["totalUsdValue"] += Number(row["total_usd_value_all_time"]);
      }

      setMetrics(temp);
      setSummary(sum);
    };

    fetchData();
  }, []);

  const findSymbolAndUrl = (mint) => {
    for (const item in tokenList) {
      if (tokenList[item]["contract"] === mint) {
        return {
          symbol: tokenList[item]["symbol"],
          imageUrl: tokenList[item]["logo"],
        };
      }
    }
  };

  return (
    <Transition.Root show={openModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setOpenModal(true)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="bg-panelColor-light fixed inset-0 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-0 text-center md:items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <Dialog.Panel className="bg-bgColor-light dark:bg-bgColor-dark text-textColor-light dark:text-textColor-dark relative transform rounded-xl text-left shadow-xl transition-all mx-3 px-3 pb-8 w-full lp:w-3/4 pc:w-2/3 h-[80vh] max-h-[600px] overflow-auto">
                <div className="flex mr-2 h-12 items-center justify-end">
                  <XMarkIcon
                    className="h-6 w-6 cursor-pointer mt-2"
                    aria-hidden="true"
                    onClick={() => setOpenModal(false)}
                  />
                </div>
                <div className="flex flex-col gap-3 items-center">
                  <p className="text-xl">Metrics</p>
                  <div className="w-full">
                    <div className="w-full flex gap-2 text-center mt-2">
                      <div className="w-[20%] pb-2 border-b-2">Wallet</div>
                      <div className="w-[20%] pb-2 border-b-2">Token</div>
                      <div className="w-[30%] pb-2 border-b-2">24H Volume</div>
                      <div className="w-[30%] pb-2 border-b-2">
                        Total Volume
                      </div>
                    </div>
                    {Object.entries(metrics).map(([key, item]) => (
                      <IndividualMetrics
                        key={key}
                        tokenList={tokenList}
                        metrics={item}
                      />
                    ))}
                    {/* Summary */}
                    <div className="w-full flex gap-2 text-center py-2 items-center border-b-2">
                      {/* Summary Title */}
                      <div className="w-[20%]">
                        Summary
                      </div>
                      {/* Token Symbol */}
                      <div className="w-[20%] px-3 flex flex-col justify-center items-center gap-1">
                        {Object.entries(summary).map(([key, item]) => {
                          const res = findSymbolAndUrl(key);
                          return res ? (
                            <div key={key} className="w-full flex justify-center items-center">
                              <img
                                src={res.imageUrl}
                                alt={res.symbol}
                                className="w-8 rounded-full"
                              />
                              <span className="flex-grow">{res.symbol}</span>
                            </div>
                          ) : null;
                        })}
                      </div>
                      {/* 24H Volume */}
                      <div className="w-[30%] px-3 flex flex-col justify-center items-center gap-1 text-[20px]">
                        {Object.entries(summary).map(([key, item]) => (
                          <div key={key} className="w-full flex justify-center items-center">
                            $ {Number(Number(item.totalUsdValue24h).toFixed(2)).toLocaleString()}
                          </div>
                        ))}
                      </div>
                      {/* Total Volume */}
                      <div className="w-[30%] px-3 flex flex-col justify-center items-center gap-1 text-[20px]">
                        {Object.entries(summary).map(([key, item]) => (
                          <div key={key} className="w-full flex justify-center items-center">
                            $ {Number(Number(item.totalUsdValue).toFixed(2)).toLocaleString()}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
