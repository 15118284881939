import { maskString } from "../../utils/maskString";

export const IndividualMetrics = ({ tokenList, metrics }) => {
  const walletAddress = metrics[0]["wallet_address"];

  const findSymbolAndUrl = (mint) => {
    for (const item in tokenList) {
      if (tokenList[item]["contract"] === mint) {
        return {
          symbol: tokenList[item]["symbol"],
          imageUrl: tokenList[item]["logo"],
        };
      }
    }
  };

  return (
    <div className="w-full flex gap-2 text-center py-2 items-center border-b-2">
      {/* Wallet address */}
      <div className="w-[20%]">{maskString(walletAddress, 15)}</div>
      {/* Token Symbol */}
      <div className="w-[20%] px-3 flex flex-col justify-center items-center gap-1">
        {metrics.map((item, key) => {
          const res = findSymbolAndUrl(item["output_mint"]);
          return res ? (
            <div key={key} className="w-full flex justify-center items-center">
              <img
                src={res.imageUrl}
                alt={res.symbol}
                className="w-8 rounded-full"
              />
              <span className="flex-grow">{res.symbol}</span>
            </div>
          ) : null;
        })}
      </div>
      {/* 24H Volume */}
      <div className="w-[30%] px-3 flex flex-col justify-center items-center gap-1 text-[20px]">
        {metrics.map((item, key) => (
          <div key={key} className="w-full flex justify-center items-center">
            $ {Number(Number(item.total_usd_value_24h).toFixed(2)).toLocaleString()}
          </div>
        ))}
      </div>
      {/* Total Volume */}
      <div className="w-[30%] px-3 flex flex-col justify-center items-center gap-1 text-[20px]">
        {metrics.map((item, key) => (
          <div key={key} className="w-full flex justify-center items-center">
            $ {Number(Number(item.total_usd_value_all_time).toFixed(2)).toLocaleString()}
          </div>
        ))}
      </div>
    </div>
  );
};
