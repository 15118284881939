export const ProgressBar = ({ flag }) => {
  const tags = ["Prepare", "Confirm", "Send"];
  return (
    <div className="relative w-2/3 text-sm flex justify-between">
      <div className="w-[80%] lp:w-[85%] tb:w-[90%] h-[2px] bg-panelColor2-light absolute top-[3px] left-[22px]"></div>
      {tags.map((item, index) => {
        if (index === flag) {
          return (
            <div key={index} className="flex flex-col gap-2 items-center z-10">
              <div className="w-2 h-2 rounded-full bg-themeColor-light"></div>
              <label className="text-themeColor-light">{item}</label>
            </div>
          );
        } else {
          return (
            <div key={index} className="flex flex-col gap-2 items-center z-10">
              <div className="w-2 h-2 rounded-full bg-panelColor-dark"></div>
              <label>{item}</label>
            </div>
          );
        }
      })}
    </div>
  );
};
