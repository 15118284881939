import { useState } from "react";
import {
  PaperAirplaneIcon,
  CheckIcon,
  ArrowTopRightOnSquareIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import { ClipLoader } from "react-spinners";
import { ActionButton } from "./ActionButton";
import { CancelButton } from "./CancelButton";
import { sendMultipleTx } from "../../hooks/functions";

export const SendPanel = ({ handleProgressIndex, amount, token }) => {
  const [status, setStatus] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tx, setTx] = useState("");

  const goToConfirm = () => {
    handleProgressIndex(1);
  };

  const sendSOL = async () => {
    setStatus(1);
    setLoading(true);
    const lamports = amount * Math.pow(10, token.decimals);
    const res = await sendMultipleTx(lamports);
    setTx(res);
    setStatus(2);
  };

  return (
    <>
      {/* Panel */}
      <div className="flex flex-col gap-3 justify-center items-center mt-6 w-full px-6 py-4">
        {status === 0 && (
          <>
            <PaperAirplaneIcon className="w-16 h-16 border border-black rounded-full p-3" />
            <span>Ready to send {token.symbol}s</span>
          </>
        )}
        {status === 1 && (
          <>
            <ClipLoader
              color="#000000"
              size={40}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <span>Sending...</span>
          </>
        )}
        {status === 2 && (
          <>
            {tx ? (
              <>
                <CheckIcon className="w-16 h-16 border border-black rounded-full p-3" />
                <span className="flex items-center gap-1">
                  Success!{" "}
                  {/* eslint-disable-next-line */}
                  <a href={`https://solscan.io/tx/${tx}`} target="_blank">
                    <ArrowTopRightOnSquareIcon className="w-5 h-5 hover:cursor-pointer" />
                  </a>{" "}
                </span>
              </>
            ) : (
              <>
                <ExclamationTriangleIcon className="w-16 h-16 border border-red-600 text-red-600 rounded-full p-3" />
                <span className="flex items-center gap-1 text-red-600">
                  Failure!
                </span>
              </>
            )}
          </>
        )}
      </div>
      {/* Next Button */}
      <div className="px-6 w-full flex justify-end gap-3 break-words text-wrap mt-3">
        {/* Button */}
        <div className="flex gap-2">
          <CancelButton handleClick={goToConfirm} buttonText="Back" />
          <ActionButton
            handleClick={sendSOL}
            buttonText="Send"
            disabled={loading}
          />
        </div>
      </div>
    </>
  );
};
