export const CancelButton = ({ handleClick, buttonText }) => {
  return (
    <button
      onClick={handleClick}
      className="px-4 py-1 bg-gray-400 text-black hover:cursor-pointer hover:bg-gray-400/70"
    >
      {buttonText}
    </button>
  );
};
