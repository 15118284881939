import { useState, useEffect } from "react";
import { db, auth } from "../../utils/firebase";
import { getDocs, collection } from "firebase/firestore";
import { ProgressBar } from "./ProgressBar";
import { PreparePanel } from "./PreparePanel";
import { ConfirmPanel } from "./ConfirmPanel";
import { SendPanel } from "./SendPanel";
import { getBalanceDirect } from "../../hooks/functions";

export const Tools = () => {
  const [amount, setAmount] = useState("");
  const [balance, setBalance] = useState("");
  const [fetchingBalance, setFetchingBalance] = useState(false);
  const [wallet, setWallet] = useState("");
  const [selectedToken, setSelectedToken] = useState({
    name: "SOL",
    symbol: "SOL",
    logo: "https://huskystudios.ams3.cdn.digitaloceanspaces.com/walletX/assets/logos/5426.png",
    contract: "So11111111111111111111111111111111111111112",
    decimals: 9
  });
  const [progressIndex, setProgressIndex] = useState(0);

  useEffect(() => {
    fetchMasterWalletBalance();
  }, []);

  useEffect(() => {
    const fetchTokenBalance = async () => {
      setFetchingBalance(true);
      const type = selectedToken.symbol === "SOL" ? "sol" : "spl";
      if (!wallet) {
        setBalance(0);
        return;
      }

      const res = await getBalanceDirect(wallet, selectedToken.contract, type);
      if (res["amount"]) {
        setBalance(res["amount"] / Math.pow(10, res["decimals"]));
      } else {
        setBalance(0);
      }
      setFetchingBalance(false);
    };

    fetchTokenBalance();
  }, [selectedToken, wallet]);

  // Fetch Wallet Addresses
  const fetchMasterWalletBalance = async () => {
    setFetchingBalance(true);
    const currentUser = auth.currentUser;
    const pathToCollection = `users/${currentUser?.uid}/accounts`;
    const collectionRef = collection(db, pathToCollection);
    const querySnapshot = await getDocs(collectionRef);
    const wallets = [];
    querySnapshot.forEach((doc) => {
      wallets.push(doc.id);
    });
    if (wallets.length) {
      setWallet(wallets[0]);
      const res = await getBalanceDirect(
        wallets[0],
        "So11111111111111111111111111111111111111112",
        "sol"
      );
      if (res["amount"]) {
        setBalance(res["amount"] / Math.pow(10, 9));
      }
    }
    setFetchingBalance(false);
  };

  return (
    <div className="absolute inset-0 flex justify-center px-2">
      <div className="flex flex-col items-center mx-2 tb:mx-0 my-auto p-4 text-center max-w-[650px] w-full rounded-xl bg-panelColor-light dark:bg-panelColor-dark text-textColor-light dark:text-textColor-dark">
        {/* Header */}
        <p className="text-xl lp:text-2xl font-medium mb-5">
          Token MultiSender Tool
        </p>
        {/* Progress Bar */}
        <ProgressBar flag={progressIndex} />
        {/* Prepare Panel */}
        {progressIndex === 0 && (
          <PreparePanel
            handleProgressIndex={setProgressIndex}
            token={selectedToken}
            handleToken={setSelectedToken}
            balance={balance}
            balanceLoading={fetchingBalance}
            handleAmount={setAmount}
          />
        )}
        {/* Confirm Panel */}
        {progressIndex === 1 && (
          <ConfirmPanel
            handleProgressIndex={setProgressIndex}
            token={selectedToken}
            amount={amount}
            balance={balance}
          />
        )}
        {/* Send Panel */}
        {progressIndex === 2 && (
          <SendPanel
            handleProgressIndex={setProgressIndex}
            amount={amount}
            token={selectedToken}
          />
        )}
      </div>
    </div>
  );
};
